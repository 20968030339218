import { initComponents } from "./functions/initComponents";

window.addEventListener("load", initialise);

function initialise() {
    initComponents();
    initMap();
}


// Google map function
function initMap() {
	// If google is defined and map element exists, run setup
	if (!(typeof google === 'undefined') && $("#map").length) {
		var latLng = {lat: 50.961358, lng: -3.043056};

		// Create a map object and specify the DOM element for display.
		var map = new google.maps.Map(document.getElementById('map'), {
			center: latLng,
			scrollwheel: false,
			zoom: 12,
			scrollwheel: false
		});

		// Create a marker and set its position.
		var marker = new google.maps.Marker({
			map: map,
			position: latLng
		});
	}
}